export default {
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'GBP',
    },
  },
  messages: {
    order_history: 'Orders & sales history',
    total: 'Total',
    include_fees: 'Include charges / fees',
    to: 'to',
    show_details: 'Show details',
    hide_details: 'Hide details',
    avg_value: 'Avg. order value',
    food_and_drinks: '(food and drink only)',
    delivery_orders: 'Delivery orders',
    collection_orders: 'Collection orders',
    cash_payments: 'Cash payments',
    card_payments: 'Card payments',
    tip_title: 'For up-to-the minute data,',
    tip_text: 'see your live reporting',
    card: 'Card',
    cash: 'Cash',
    delivery: 'Delivery',
    collection: 'Collection',
    service: 'Service',
    cancelled: 'Cancelled',
    completed: 'Completed',
    search_placeholder: 'Search order #',
    status: 'Status',
    delivery_address: 'Delivery address',
    orderN: 'Order #',
    transactionN: 'Transaction no.',
    pcs: 'Pcs.',
    name: 'Name',
    description: 'Description',
    unit_price: 'Unit Price',
    service_charge: 'Service charges',
    delivery_fee: 'Delivery fees',
    restaurant_discount: 'Restaurant discount',
    stamp_card_discount: 'StampCard discount',
    voucher_discount: 'Voucher discount',
    additional_info: 'Additional information',
    comments: 'Comments',
    account_credits: 'Account credits',
    refund_amount: 'Refund amount',
    out_of_stock_title: 'Out of stock items',
    substituted_title: 'Substituted items',
    gdpr_notice: 'GDPR notice',
    gdpr_hint: 'Customer information is hidden 24 hours after the order was completed',
    order_details: 'Order details',
    order_placed: 'Order placed:',
    order_due: 'Order due:',
    asap: 'ASAP',
    on: 'on',
    cancellation_reason: 'Cancellation reason:',
    clear: 'Clear',
    apply: 'Apply',
    filter_by: 'Filter by',
    order_status: 'Order status',
    payment_type: 'Payment type',
    filters: 'Filters',
    date_range: 'Date range',
    one_day_tip:
      'To see orders for <span class="font-bold">one day only</span>, select your chosen day twice then select <span class="font-bold">Apply</span>.',
    yesterday: 'Yesterday',
    last_week: 'Last week',
    last_month: 'Last month',
    no_orders: 'No orders found',
    change_filters: 'Try changing your filters or date range.',
    remove_filters: 'Remove filters',
    service_type: 'Service type',
    not_accessible: 'That order cannot be shown',
    not_accessible_hint:
      'Check that you entered the order number correctly, and that the order was from your restaurant in the last 30 days.',
    toofar: 'Too Far',
    fakedelivered: 'Fake Delivered',
    fakenotdelivered: 'Fake Not Delivered',
    faxnotreceived: 'Fax Not Received',
    incorrectorder: 'Incorrect Order',
    noanswerfromrestaurant: 'No Answer From Restaurant',
    missedbyrestaurant: 'Missed By Restaurant',
    fakenotcollected: 'Fake Not Collected',
    restaurantcannotdeliver: 'Restaurant Cannot Deliver',
    rejectedbyrestaurant: 'Rejected By Restaurant',
    newtimerejected: 'New Time Rejected',
    systemerror: 'System Error',
    restaurantisbusy: 'Restaurant Is Busy',
    testorder: 'Test Order',
    free: 'Free',
    back_to_top: 'Back to the top',
    delivery_charges: 'delivery fees',
    bag_fee: 'Bag fee',
    plus_premium: 'Just Eat+ Premium',
    small_order_fee: 'Small order fee',
    dinein: 'Dine-in',
    dinein_orders: 'Dine-in orders',
    orders: 'order | orders',
    restaurant_note: 'Notes for the restaurant',
    delivery_note: 'Delivery notes',
    empty_notes: 'No notes for this order',
    banner_title: 'Fast and flexible business funding from YouLend',
    banner_text:
      "Get funding from £500-£1million with flexible business funding from the UK's leading alternative finance provider.",
    banner_url: 'https://youlend.com/apply/justeat/welcome',
    banner_cta: 'Get a quote',
  },
  dateTimeFormats: {
    weekday: {
      weekday: 'short',
    },
    day: {
      month: 'short',
      day: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
  },
}
